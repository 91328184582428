:root {
    --bg-color: #ff763f
}

.error-boundray__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.error-boundray__container-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.error-boundray__container-content>h1 {
    font-size: 2.5rem;
}

.error-connecting__main {
    margin-top: 5rem;
}

.error-boundray__home-btn {
    margin-top: 2rem;
    text-decoration: none;
    color: inherit;
    padding: 1rem;
    /* border: var(--bg-color) 0.1rem solid; */
    border-radius: 0.5rem;
    transition: all 0.3s ease-in-out;
}

.error-boundray__home-btn:hover {
    color: #fff;
    background-color: var(--bg-color);
}